@import url('https://fonts.googleapis.com/css?family=Open+Sans');

* {
  box-sizing: border-box;
}

html, body  {
  background-color: #213d55;
  line-height: 1.3;
  position: relative;
}

body {
  background: #ffffff;
  color: #213d55;
  font-family: 'Open Sans',Helvetica,Arial,sans-serif;
  font-size: 16px;
  height: 100%;
  margin: 0 auto;
  max-width: 39.375em;
  overflow: auto;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
h1 {
  font-size: 2em;
}
h2, h3 {
  font-size: 1.5em;
}

p {
  margin-bottom: 16px;
}
ol, ul {
  padding: 0;
 li  {
   list-style-position: inside;
 }
}

.ContentBody {
  font-weight: 300;
  overflow: auto;
}

.SiteHead {
  background-color: #fff;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  &-brand {
    margin: 25px;
  }
  .Logo {
    &--siteHead {
      display: block;
      text-align: center;
    }
    &-graphic {
      max-width: 125px;
    }
  }
}
.Paper {
  &-body {
    text-align: center;
  }
  &-header {
    padding: 25px 10px;
    background-color: #c0d6dd;
    border-bottom: #dae8eb;
  }
  &-copy {
    padding: 16px 10px;
  }
  &-headline {
    color: #2d9ec5;
    font-size: 1.375em;
    margin: 10px 0;
  }
  &-lead {
    font-size: 1.500em;
    font-weight: 300;
  }

}
.SiteFooter-brand {
  color: #2d9ec5;
  padding: 16px 20px;
  overflow: auto;
  .Logo--siteFooter {
    color: #2d9ec5;
    display: inline-block;
    font-weight: 700;
    text-decoration: none;
    font-size: 1.125em;
    float: right;
    img {
      max-width: 7.8125em;
    }
  }
}

@media screen and (min-width: 22.500em) {
  html, body {
    line-height: 1.5;
  }

  ol, ul {
    padding: 0 0 0 40px;
    li {
      list-style-position: outside;
    }
  }

  .SiteHead {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-between;
  }
  .Paper {
    &-body {
      text-align: left;
    }
    &-header {
      padding: 25px;
    }
    &-copy {
      padding: 16px 30px;
    }
    &-headline {
      font-size: 2em;
      line-height: 1.25;
    }
  }

}
